import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {

  IsShowLoading: boolean = false;
  Controller: string = "Transaction";
  APIURL: string = environment.APIRootURL;
  KeyPublic: string = environment.KeyPublic;
  KeyPublicFormat: string = environment.KeyPublicFormat;
  Action1: string = "GetByTokenAsync";
  URL1: string = environment.APIURL + this.Controller + "/" + this.Action1 + "?Token=" + environment.TransactionToken;
  Data1: string = environment.InitializationString;
  Action2: string = "GetByBlockTokenToListAsync";
  URL2: string = environment.APIURL + this.Controller + "/" + this.Action2 + "?BlockToken=" + environment.BlockToken;
  Data2: string = environment.InitializationString;
  Action3: string = "GetByKeyPublicToListAsync";
  URL3: string = environment.APIURL + this.Controller + "/" + this.Action3 + "?" + this.KeyPublicFormat + "=" + environment.KeyPublic;
  Data3: string = environment.InitializationString;
  constructor(
    public httpClient: HttpClient
  ) { }

  ngOnInit(): void {
  }

  TryItOut1() {
    this.IsShowLoading = true;
    this.httpClient.get(this.URL1).toPromise().then(
      res => {
        this.Data1 = JSON.stringify(res);
      },
      err => {
        this.Data1 = JSON.stringify(err);
      }
    ).finally(() => {
      this.IsShowLoading = false;
    });
  }
  TryItOut2() {
    this.IsShowLoading = true;
    this.httpClient.get(this.URL2).toPromise().then(
      res => {        
        this.Data2 = JSON.stringify(res);
      },
      err => {        
        this.Data2 = JSON.stringify(err);
      }
    ).finally(() => {
      this.IsShowLoading = false;
    });
  }
  TryItOut3() {
    this.IsShowLoading = true;
    this.httpClient.get(this.URL3).toPromise().then(
      res => {        
        this.Data3 = JSON.stringify(res);
      },
      err => {        
        this.Data3 = JSON.stringify(err);
      }
    ).finally(() => {
      this.IsShowLoading = false;
    });
  }
}