import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class DownloadService {

   


    APIURL: string = environment.APIUploadURL;
    Controller: string = "Download";
    Headers: HttpHeaders = new HttpHeaders();


    IPAddress: string = environment.InitializationString;


    constructor(private httpClient: HttpClient) {
       
    }
    
}

