import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';

import { AccountComponent } from './account/account.component';
import { BlockComponent } from './block/block.component';
import { TransactionComponent } from './transaction/transaction.component';

const routes: Routes = [
  { path: '', redirectTo: '/Homepage', pathMatch: 'full' },  
  {
    path: 'Homepage', component: HomepageComponent,
  },
  {
    path: 'Account', component: AccountComponent,
  },
  {
    path: 'Block', component: BlockComponent,
  },
  {
    path: 'Transaction', component: TransactionComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }









































































