<div class="wrapper">
      <header class="main-header">
            <div class="d-flex align-items-center logo-box justify-content-start">
                  <a href="#" class="logo">
                        <div class="logo-lg">
                              <span class="light-logo"><img src="assets/image/logo60.png" alt="logo"></span>
                              <span class="dark-logo"><img src="assets/image/logo60.png" alt="logo"></span>
                        </div>
                        &nbsp;&nbsp;&nbsp;<span style="font-size: 30px;">API</span>
                  </a>
            </div>
            <nav class="navbar navbar-static-top">
                  <div class="app-menu">
                        <ul class="header-megamenu nav">
                              <li class="btn-group nav-item">
                                    <a href="#" class="waves-effect waves-light nav-link push-btn btn-primary-light"
                                          data-toggle="push-menu" role="button">
                                          <i data-feather="align-left"></i>
                                    </a>
                              </li>
                              <li class="btn-group nav-item d-none d-xl-inline-block" style="font-size: 30px;">
                                    The Enterprise Blockchain Platform - API Document
                              </li>
                        </ul>
                  </div>
                  <div class="navbar-custom-menu r-side">
                        <ul class="nav navbar-nav">
                              <li class="btn-group nav-item d-lg-inline-flex d-none">
                                    <a href="#" data-provide="fullscreen"
                                          class="waves-effect waves-light nav-link full-screen btn-primary-light"
                                          title="Full Screen">
                                          <i data-feather="maximize"></i>
                                    </a>
                              </li>
                              <li class="dropdown user user-menu">
                                    <a title="Register" target="_blank" href="https://login.dgkchain.com/#/Register" class="waves-effect waves-light dropdown-toggle btn-primary-light">
                                          <i data-feather="user"></i>
                                    </a>                                   
                              </li>                            
                        </ul>
                  </div>
            </nav>
      </header>
      <aside class="main-sidebar">
            <section class="sidebar position-relative">
                  <div class="multinav">
                        <div class="multinav-scroll" style="height: 100%;">
                              <ul class="sidebar-menu" data-widget="tree">
                                    <li class="treeview">
                                          <a routerLink="{{domainName}}Account">
                                                <i data-feather="users"></i>
                                                <span>Account</span>
                                          </a>
                                    </li>
                                    <li class="treeview">
                                          <a routerLink="{{domainName}}Block">
                                                <i data-feather="dollar-sign"></i>
                                                <span>Block</span>
                                          </a>
                                    </li>
                                    <li class="treeview">
                                          <a routerLink="{{domainName}}Transaction">
                                                <i data-feather="sliders"></i>
                                                <span>Transaction</span>
                                          </a>
                                    </li>                                   
                                    
                                    <li class="treeview">
                                          <a routerLink="{{domainName}}Homepage">
                                                <i data-feather="cast"></i>
                                                <span>Privacy Policy</span>
                                          </a>
                                    </li>                                   
                              </ul>
                              <div class="sidebar-widgets">
                                    <div class="mx-25 mb-30 p-30 text-center bg-primary-light rounded5">
                                          <img src="assets/images/trophy.png" alt="">
                                          <h4 class="my-3 fw-500 text-uppercase text-primary">Start Trading</h4>
                                          <span class="fs-12 d-block mb-3 text-black-50">Offering discounts for better
                                                online a
                                                store can loyalty weapon into driving</span>
                                          <button type="button"
                                                class="waves-effect waves-light btn btn-sm btn-primary mb-5">Invest
                                                Now</button>
                                    </div>
                                    <div class="copyright text-center m-25">
                                          <p><strong class="d-block">API Document</strong>
                                          </p>                                        
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
      </aside>
      <div class="content-wrapper">
            <div class="container-full">
                  <router-outlet></router-outlet>
            </div>
      </div>
      <footer class="main-footer">
            <div class="pull-right d-none d-sm-inline-block">
                  <ul
                        class="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
                        <li class="nav-item">
                              <a class="nav-link" href="https://dgk.vn/" title="Designed and developed by DGK"
                                    target="_blank">Designed and developed by DGK</a>
                        </li>
                  </ul>
            </div>
            &copy; 2024 <a href="https://dgkchain.com/" title="DGK chain" target="_blank">DGK chain</a>. All Rights
            Reserved.
      </footer>

      <aside class="control-sidebar">
            <div class="rpanel-title"><span class="pull-right btn btn-circle btn-danger"><i
                              class="ion ion-close text-white" data-toggle="control-sidebar"></i></span> </div>
            <ul class="nav nav-tabs control-sidebar-tabs">
                  <li class="nav-item"><a href="#control-sidebar-home-tab" data-bs-toggle="tab" class="active"><i
                                    class="mdi mdi-message-text"></i></a></li>
                  <li class="nav-item"><a href="#control-sidebar-settings-tab" data-bs-toggle="tab"><i
                                    class="mdi mdi-playlist-check"></i></a></li>
            </ul>
      </aside>
      <div class="control-sidebar-bg"></div>
</div>