export const environment = {
  production: false,
  DomainURL: "",
  DomainDestination: "",  
  APIURL: "https://api.dgkchain.com/api/v1/",
  APIRootURL: "https://api.dgkchain.com/",
  APIUploadURL: "",
  APIUploadRootURL: "",
  LoginURL: "https://login.dgkchain.com/",
  Website: "https://dgkchain.com/",  
  IPRegistry: "https://ipv4.myexternalip.com/json",
  IPRegistryURL: "https://api.ipregistry.co/?key=tryout",
  LoadingFile: "loading.gif",
  DialogConfigWidth: "80%",
  DialogConfigWidth60: "60%",
  InitializationString: "",
  InitializationNumber: 0,  
  DanhMucQuocGiaIDVietNam: 237,  
  DanhMucUngDungID: 1,  
  MapZoom: 8,
  Latitude: 10.624748091691929,
  Longitude: 105.9665262379964,
  PageSize: 10,
  MaptilerAPIKey: "6iFTqps4QVACLERa26MA",
  KeyPublic: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiJKV1RTZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiJkMzQ3ZWVhMS1kMGE3LTRhYjItYWI3NC1iN2VhNGY0MDFlMjciLCJpYXQiOiI3LzI1LzIwMjQgOTozNjozMyBBTSIsIklEIjoiNCIsImV4cCI6MTcyNDQ5MjE5MywiaXNzIjoiSldUQXV0aGVudGljYXRpb25TZXJ2ZXIiLCJhdWQiOiJKV1RTZXJ2aWNlUG9zdG1hbkNsaWVudCJ9eP2fK0x5JNbtB0GGuW5vzRnB98bLkqp4Ep2mK4Y",
  KeyPublicFormat: "KeyPublic",
  TransactionToken: "0x971B39545463E32321Ac966f6c4B134f0819f14a",
  BlockToken: "0x0Fa5d7bee1622F52dd5079eA67D062Ba74F69fd6",
  TokenFCM: "TokenFCM",
  MaSo: "00000000",
  Homepage: "Homepage",
  Login: "Login",
  Bearer: "Bearer null",
  ThanhVienToChucID: "ThanhVienToChucID",
  ThanhVienParentID: "ThanhVienParentID",
  ThanhVienID: "ThanhVienID",
  ThanhVienHoTen: "ThanhVienHoTen",
  ThanhVienTaiKhoan: "ThanhVienTaiKhoan",
  ThanhVienFileName: "ThanhVienFileName",
  ThanhVienDiaChiVi: "ThanhVienDiaChiVi",
  UploadSuccess: "Upload success.",
  UploadNotSuccess: "Upload not success.",
  SaveSuccess: "Save success.",
  SaveNotSuccess: "Save not success.",
  DeleteConfirm: "Do you want to delete this data?",
  DeleteSuccess: "Delete success.",
  DeleteNotSuccess: "Delete not success.",
  LoginNotSuccess: "Authentication not success.",
  UserNameExists: "Account already exists.",
  UserNameRequired: "UserName is required.",
  ToChucExists: "Account already exists.",
  PageTitle: "The Enterprise Blockchain Platform",
  PageTitleShort: "Blockchain - CRM",
  PageDescription: "Blockchain",
};
