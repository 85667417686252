import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.css']
})
export class BlockComponent implements OnInit {

  IsShowLoading: boolean = false;
  Controller: string = "Block";
  APIURL: string = environment.APIRootURL;
  KeyPublic: string = environment.KeyPublic;
  KeyPublicFormat: string = environment.KeyPublicFormat;
  Action1: string = "GetByTokenAsync";
  URL1: string = environment.APIURL + this.Controller + "/" + this.Action1 + "?Token=" + environment.TransactionToken;
  Data1: string = environment.InitializationString;
  Action2: string = "GetByKeyPublicToListAsync";
  URL2: string = environment.APIURL + this.Controller + "/" + this.Action2 + "?" + this.KeyPublicFormat + "=" + environment.KeyPublic;
  Data2: string = environment.InitializationString;
  Action3: string = "CreateAsync";
  URL3: string = environment.APIURL + this.Controller + "/" + this.Action3;
  Parameter3: string = environment.InitializationString;
  Data3: string = environment.InitializationString;
  constructor(
    public httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.Parameter3 = '{KeyPublic: "' + this.KeyPublic + '", Name: "DGK", Type: "Create", Function: "Create new Block", SendTo: "0xfC4EeC16E8B7A5897f14b40708804Cf216f12810", JSONData: "{"SanPhamSoLuongMa":null,"MaSo":"00000000","QRCode":"0xEB564D603E8e853EeD2FD531962B2100Ce9d5Fa6","URL":"https://traceability.blockchain.carbonunit.net/#/Homepage/0xEB564D603E8e853EeD2FD531962B2100Ce9d5Fa6","SoLanKiemTra":null,"TemPhieuKhoiTaoChiTietID":null,"ID":36054,"ParentID":59,"ParentName":"HTX NN Phước Hảo","CreatedDate":"2024-07-25T13:23:05.9168515+07:00","CreatedMembershipID":null,"LastUpdatedDate":"2024-07-25T13:23:05.9168492+07:00","LastUpdatedMembershipID":null,"RowVersion":1,"SortOrder":10,"Active":true,"TypeName":null,"Name":null,"Code":"90740922-91a2-4dec-b4a8-62d9a8ba667e","Note":null,"Display":null,"FileName":"http://localhost:5129/Image/TemPhieu/0xEB564D603E8e853EeD2FD531962B2100Ce9d5Fa6.png","Description":null,"HTMLContent":null,"DanhMucNgonNguID":null,"DanhMucUngDungID":null,"DanhMucNgonNguName":null,"DanhMucUngDungName":null}", Action: 3}';
  }

  TryItOut1() {
    this.IsShowLoading = true;
    this.httpClient.get(this.URL1).toPromise().then(
      res => {
        this.Data1 = JSON.stringify(res);
      },
      err => {
        this.Data1 = JSON.stringify(err);
      }
    ).finally(() => {
      this.IsShowLoading = false;
    });
  }
  TryItOut2() {
    this.IsShowLoading = true;
    this.httpClient.get(this.URL2).toPromise().then(
      res => {
        this.Data2 = JSON.stringify(res);
      },
      err => {
        this.Data2 = JSON.stringify(err);
      }
    ).finally(() => {
      this.IsShowLoading = false;
    });
  }
  TryItOut3() {    
    this.Data3 = JSON.stringify(environment.BlockToken);
  }
}