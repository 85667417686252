<section class="content">
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="box">
                <div class="box-header with-border">
                    <h4 class="box-title"><b>[{{Controller}}] 1. Get information by Token</b></h4>
                </div>
                <div class="box-body">
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>URL: <b>{{APIURL}}api/[version]/{{Controller}}/{{Action1}}</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Method: <b>GET</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Parameter: <b>Token: string</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Return: <b>JSON data</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Format:
                                    <b>{{APIURL}}api/v1/{{Controller}}/{{Action1}}?Token=[Token]</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Real:
                                    <b>{{APIURL}}swagger/index.html</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Example:</label>
                                <input type="text" class="form-control" [(ngModel)]="URL1">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <a style="width: 100%;" title="Try it out" (click)="TryItOut1()"
                                    class="btn btn-success">Try it out</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <textarea [(ngModel)]="Data1" type="text" class="form-control" rows="13"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="box-header with-border">
                    <h4 class="box-title"><b>[{{Controller}}] 2. Get List by Block Token</b></h4>
                </div>
                <div class="box-body">
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>URL: <b>{{APIURL}}api/[version]/{{Controller}}/{{Action2}}</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Method: <b>GET</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Parameter: <b>BlockToken: string</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Return: <b>JSON list</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Format:
                                    <b>{{APIURL}}api/v1/{{Controller}}/{{Action2}}?BlockToken=[BlockToken]</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Real:
                                    <b>{{APIURL}}swagger/index.html</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Example:</label>
                                <input type="text" class="form-control" [(ngModel)]="URL2">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <a style="width: 100%;" title="Try it out" (click)="TryItOut2()"
                                    class="btn btn-success">Try it out</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <textarea [(ngModel)]="Data2" type="text" class="form-control" rows="13"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="box-header with-border">
                    <h4 class="box-title"><b>[{{Controller}}] 3. Create</b></h4>
                </div>
                <div class="box-body">
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>URL: <b>{{APIURL}}api/[version]/{{Controller}}/{{Action3}}</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Method: <b>POST</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Parameter: <b>JSON</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label><b>{{KeyPublicFormat}}: string (required)</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label><b>Name: string (optional)</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label><b>Type: string (optional)</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label><b>Function: string (optional)</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label><b>SendTo: string (optional)</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label><b>JSONData: string (optional)</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label><b>Action: integer (optional)</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label><b>Create: 3</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label><b>Edit: 2</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label><b>Remove: 1</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Return: <b>JSON string</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Format:
                                    <b>{{APIURL}}api/v1/{{Controller}}/{{Action3}}</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label>Real:
                                    <b>{{APIURL}}swagger/index.html</b></label>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Example:</label>                                
                                <textarea [(ngModel)]="Parameter3" type="text" class="form-control" rows="10"></textarea>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <a style="width: 100%;" title="Try it out" (click)="TryItOut3()"
                                    class="btn btn-success">Try it out</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <textarea [(ngModel)]="Data3" type="text" class="form-control" rows="30"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-loading *ngIf="IsShowLoading"></app-loading>